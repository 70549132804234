import type { ObjectPrivilege } from 'cadenza/access-management/model/object-privilege';
import type { TableData, TableParams } from 'cadenza/api-client/tables-api';
import { tableParams } from 'cadenza/api-client/tables-api';
import { ajax } from 'cadenza/api-client/api';
import { MIMETYPE_JSON } from 'cadenza/model/media-type';
import type { ExternalLinkActionName } from 'cadenza/repository/model/external-link-action-name';
import type { RepositoryName } from 'cadenza/repository/model/repository-name';
import type { ExternalBaseLinkId } from 'cadenza/repository/model/external-base-link-id';
import type { ExternalLinkDrillThroughName } from 'cadenza/repository/model/external-link-drill-through-name';
import type { ExternalLinkExtensionName } from 'cadenza/repository/model/external-link-extension-name';
import type {
  DrillThroughType,
  ExternalBaseLinkDto,
  ExternalBaseLinkListingDto,
  ExternalDrillThroughDto,
  ExternalLinkExtensionDto,
  ExternalLinkPostMessageActionDto,
  MutableExternalBaseLinkDto,
  MutableExternalDrillThroughDto,
  MutableExternalLinkExtensionDto,
} from 'cadenza/admin/external-links/dto/external-link-dto';
import type { ExternalLinkTemplateDto } from 'cadenza/admin/external-links/dto/external-link-template-dto';

export function getExternalLinksList({
  withExtensions,
  withActions,
  privilege,
  offset = 0,
  limit = 500,
  ...params
}: TableParams & {
  withExtensions?: boolean;
  withActions?: boolean;
  privilege?: ObjectPrivilege;
}) {
  const requestParams = tableParams({ offset, limit, ...params });
  if (withExtensions !== undefined) {
    requestParams.set('withExtensions', String(withExtensions));
  }
  if (withActions !== undefined) {
    requestParams.set('withActions', String(withActions));
  }
  if (privilege) {
    requestParams.set('privilege', privilege);
  }
  return ajax<TableData<ExternalBaseLinkListingDto>>(`/externallinks?${requestParams}`, {
    dataType: 'json',
  });
}

export function getExternalLink({ repositoryName, itemName }: ExternalBaseLinkId) {
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/${itemName}`);
}

export function createExternalLink(repositoryName: RepositoryName, externalBaseLinkDto: MutableExternalBaseLinkDto) {
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalBaseLinkDto),
  });
}

export function getExternalLinkTemplates(): Promise<ExternalLinkTemplateDto[]> {
  return ajax<ExternalLinkTemplateDto[]>('/config/external-link-templates', {
    type: 'GET',
    contentType: MIMETYPE_JSON,
  });
}

export function updateExternalLink(
  { repositoryName, itemName }: ExternalBaseLinkId,
  details: Partial<MutableExternalBaseLinkDto>,
) {
  const patch = { baseLinkName: itemName, repositoryName, ...details };
  return ajax<ExternalBaseLinkDto>(`/repositories/${repositoryName}/externallinks/${itemName}`, {
    type: 'PATCH',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(patch),
  });
}

export function deleteExternalLink({ repositoryName, itemName }: ExternalBaseLinkId) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}`, {
    type: 'DELETE',
  });
}

export async function getVisibleExternalLinkExtensions({ repositoryName, itemName }: ExternalBaseLinkId) {
  return ajax<ExternalLinkExtensionDto[]>(`/repositories/${repositoryName}/externallinks/${itemName}/extensions`);
}

interface BaseLinkWithExtensionPair {
  baseLink: ExternalBaseLinkDto;
  extension: ExternalLinkExtensionDto;
}

export async function getExternalLinkExtensionOrThrow(
  baseLinkId: ExternalBaseLinkId,
  extensionName: ExternalLinkExtensionName,
): Promise<BaseLinkWithExtensionPair> {
  const baseLink = await getExternalLink(baseLinkId);
  const extension = baseLink.extensions.find((ext) => ext.linkExtensionName === extensionName);
  if (!extension) {
    throw new Error(`Failed to fetch External link extension "${extensionName}"`);
  }
  return { baseLink, extension };
}

export async function getExternalLinkPostMessageAction(
  baseLinkId: ExternalBaseLinkId,
  actionName: ExternalLinkActionName,
) {
  const baseLink = await getExternalLink(baseLinkId);
  const action = baseLink.postMessageActions.find((a) => a.actionName === actionName);
  if (!action) {
    return undefined;
  }
  return { baseLink, action };
}

export function createExternalLinkExtension(
  { repositoryName, itemName }: ExternalBaseLinkId,
  externalLinkExtensionDto: MutableExternalLinkExtensionDto,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/extensions/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalLinkExtensionDto),
  });
}

export function updateExternalLinkExtension(
  { repositoryName, itemName }: ExternalBaseLinkId,
  linkExtensionName: ExternalLinkExtensionName,
  details: ExternalLinkExtensionDto,
) {
  return ajax<ExternalLinkExtensionDto>(
    `/repositories/${repositoryName}/externallinks/${itemName}/extensions/${linkExtensionName}`,
    {
      type: 'PUT',
      contentType: MIMETYPE_JSON,
      data: JSON.stringify(details),
    },
  );
}

export function deleteExternalLinkExtension(
  { repositoryName, itemName }: ExternalBaseLinkId,
  linkExtensionName: ExternalLinkExtensionName,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/extensions/${linkExtensionName}`, {
    type: 'DELETE',
  });
}

export function createExternalLinkDrillThrough(
  { repositoryName, itemName }: ExternalBaseLinkId,
  externalLinkDrillThroughDto: MutableExternalDrillThroughDto,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/drillthroughs/`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalLinkDrillThroughDto),
  });
}

export function getExternalLinkDrillThroughs<T extends DrillThroughType>(
  { repositoryName, itemName }: ExternalBaseLinkId,
  type: T,
) {
  return ajax<ExternalDrillThroughDto<T>[]>(
    `/repositories/${repositoryName}/externallinks/${itemName}/drillthroughs?type=${type}`,
  );
}

export function updateExternalLinkDrillThrough(
  { repositoryName, itemName }: ExternalBaseLinkId,
  linkDrillThroughName: ExternalLinkDrillThroughName,
  details: MutableExternalDrillThroughDto,
) {
  return ajax<ExternalDrillThroughDto<'url'>>(
    `/repositories/${repositoryName}/externallinks/${itemName}/drillthroughs/${linkDrillThroughName}`,
    {
      type: 'PUT',
      contentType: MIMETYPE_JSON,
      data: JSON.stringify(details),
    },
  );
}

export function deleteExternalLinkDrillThrough(
  { repositoryName, itemName }: ExternalBaseLinkId,
  linkDrillThroughName: ExternalLinkDrillThroughName,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/drillthroughs/${linkDrillThroughName}`, {
    type: 'DELETE',
  });
}

export function createExternalLinkPostMessageAction(
  { repositoryName, itemName }: ExternalBaseLinkId,
  externalLinkPostMessageActionDto: Omit<ExternalLinkPostMessageActionDto, 'actionName'>,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/actions`, {
    type: 'POST',
    contentType: MIMETYPE_JSON,
    data: JSON.stringify(externalLinkPostMessageActionDto),
  });
}

export function updateExternalLinkPostMessageAction(
  { repositoryName, itemName }: ExternalBaseLinkId,
  details: ExternalLinkPostMessageActionDto,
) {
  return ajax<ExternalDrillThroughDto<'url'>>(
    `/repositories/${repositoryName}/externallinks/${itemName}/actions/${details.actionName}`,
    {
      type: 'PUT',
      contentType: MIMETYPE_JSON,
      data: JSON.stringify(details),
    },
  );
}

export function deleteExternalLinkPostMessageAction(
  { repositoryName, itemName }: ExternalBaseLinkId,
  actionName: ExternalLinkActionName,
) {
  return ajax<void>(`/repositories/${repositoryName}/externallinks/${itemName}/actions/${actionName}`, {
    type: 'DELETE',
  });
}

export function getExternalLinkPostMessageActions({ repositoryName, itemName }: ExternalBaseLinkId) {
  return ajax<ExternalLinkPostMessageActionDto[]>(`/repositories/${repositoryName}/externallinks/${itemName}/actions`);
}
