/*
 * The execution of this "legacy-base" bundle is render-blocking. It contains initialization code
 * that inline scripts on the pages rely upon. We don't want that and we eventually want to get rid
 * of this bundle (and the inline scripts).
 *
 * In general, we want to know the dependencies of our pages, so their dependencies should be defined
 * explicitly in the respective page's bundle. If you *really* need to have things globally on every
 * Cadenza page, consider adding them to the "base" bundle: The execution of that bundle is deferred,
 * i.e. it's not render-blocking.
 */

// public path and polyfills need to be setup first.
import 'cadenza/public-path';
import 'cadenza/polyfills';

import $ from 'jquery';
import { logger } from 'cadenza/utils/logging';
import { applyThemeProperties } from 'cadenza/theming-api';
import { on } from 'cadenza/utils/event-util';
import { subscribeToEvent } from 'cadenza/integration/post-message';

const Disy = window.Disy;

applyThemeProperties(Disy.theme);

window.disyCommons = {
  logger,
  initializeNavigation: () => {
    // If the start page is loaded, we need to initialize navigation.
    const expandNavigator = isExpandNavigator();
    if (isStartPage() || isProjectStartPage() || expandNavigator) {
      importAndInitializeNavigator(expandNavigator);
      return;
    }

    // Otherwise we initialize navigation on the first click of the navigationTrigger button.
    // After the initialization we need to programmatically click on the button one more time
    // because only then will the click event responsible for showing navigation be triggered.
    const navigationTrigger = document.getElementById('navigationTrigger');
    if (navigationTrigger) {
      on(
        navigationTrigger,
        'click',
        () => importAndInitializeNavigator(true),
        { once: true });
    }

    const unsubscribe = subscribeToEvent('expandNavigator', ({ detail: { expandNavigator } }) => {
      if (expandNavigator) {
        unsubscribe();
        importAndInitializeNavigator(true);
      }
    });
  }
};

function isStartPage () {
  const page = document.querySelector('.d-page');
  return page?.matches('.welcome-page');
}

function isProjectStartPage () {
  return document.querySelector('.d-page')?.matches('.project-start-page');
}

function isExpandNavigator () {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('expandNavigator') === 'true';
}

// We return a promise which is finished once the navigation is initialized.
async function importAndInitializeNavigator (toggleState) {
  // check if navigation is already initialized to prevent multiple instances of it
  // aria-describedby is set by initializeNavigation function
  if (!$('#navigationTrigger').attr('aria-describedby')) {
    const { initializeNavigator } = await import(/* webpackChunkName: "navigator" */ 'cadenza/navigator/navigator');
    await initializeNavigator(toggleState);
  }
}

if (Disy.debug) {
  logger.enableAll(false); // do not persist the log level in localStorage
}

// Make jQuery globally available for inline scripts (yes, they're bad)
window.$ = $;
window.jQuery = $;
