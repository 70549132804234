// Getter and Setter implemented according to:
// https://html.spec.whatwg.org/multipage/custom-elements.html#accessibility-semantics

polyfillAriaBoolean('ariaDisabled', 'aria-disabled');
polyfillAriaBoolean('ariaReadOnly', 'aria-readonly');
polyfillAriaBoolean('ariaRequired', 'aria-required');

function polyfillAriaBoolean(prop: string, attr: string) {
  // eslint-disable-next-line no-prototype-builtins
  if (!Element.prototype.hasOwnProperty(prop)) {
    Object.defineProperty(Element.prototype, prop, {
      enumerable: true,
      get(): string | null {
        return this.getAttribute(attr);
      },
      set(required: string | null) {
        if (required === 'true') {
          this.setAttribute(attr, 'true');
        } else {
          this.removeAttribute(attr);
        }
      },
    });
  }
}
