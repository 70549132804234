import { getLogger } from 'cadenza/utils/logging';
import { isFeatureAvailable } from 'cadenza/features';
import { assertNonNullable } from 'cadenza/utils/custom-error';
import type { SelectionEventDetail } from 'cadenza/integration/cadenza-api-selection-util';
import type { Teardown } from 'cadenza/utils/teardown';
import { lazy } from 'cadenza/utils/promise-utils';
import { getTargetOrigin } from 'cadenza/workbook/link/cadenza-link-utils';

/* eslint-disable @typescript-eslint/no-restricted-imports */
import type {
  CadenzaEvent,
  CadenzaEventByType as PublicCadenzaEventByType,
  CadenzaEventType as PublicCadenzaEventType,
  CustomValidityType,
  Extent,
  FilterVariables,
  WorkbookLayerPath,
} from '@disy/cadenza.js';
import type { Geometry } from 'geojson';
/* eslint-enable @typescript-eslint/no-restricted-imports */

const logger = getLogger('cadenza/integration/post-message');

if (isFeatureAvailable('CADENZA_JS_SANDBOX')) {
  logger.enableAll();
}

type OutgoingCadenzaEventType = PublicCadenzaEventType | 'ready' | 'change:extent';
type OutgoingCadenzaEvent<T extends OutgoingCadenzaEventType> =
  T extends PublicCadenzaEventType ? PublicCadenzaEventByType<T>
  : T extends 'change:extent' ? CadenzaEvent<T, { extent: Extent }>
  : never;

type IncomingCadenzaEventType =
  | 'expandNavigator'
  | 'getData'
  | 'setCustomValidity'
  | 'setGeometry'
  | 'setFilter'
  | 'setLayerVisibility'
  | 'setSelection'
  | 'addSelection'
  | 'removeSelection'
  | 'reload'
  | 'closeMe';

export type IncomingCadenzaEvent<T extends IncomingCadenzaEventType = IncomingCadenzaEventType> = {
  responsePort?: MessagePort;
} & (T extends 'expandNavigator' ? CadenzaEvent<T, { expandNavigator: boolean }>
: T extends 'getData' ? CadenzaEvent<T, never>
: T extends 'setCustomValidity' ? CadenzaEvent<T, { message: string; type: CustomValidityType }>
: T extends 'setGeometry' ? CadenzaEvent<T, { geometry: Geometry; zoomToGeometry?: boolean }>
: T extends 'setFilter' ? CadenzaEvent<T, { filter: FilterVariables }>
: T extends 'setLayerVisibility' ? CadenzaEvent<T, { layer: WorkbookLayerPath; visible: boolean }>
: T extends 'setSelection' ? CadenzaEvent<T, SelectionEventDetail>
: T extends 'addSelection' ? CadenzaEvent<T, SelectionEventDetail>
: T extends 'removeSelection' ? CadenzaEvent<T, SelectionEventDetail>
: T extends 'reload' ? CadenzaEvent<T, { invalidateCaches: boolean }>
: never);

type Subscriber<T extends IncomingCadenzaEventType = never> = (event: IncomingCadenzaEvent<T>) => void;

/**
 * Represents one window (target) that this cadenza instance is communicating with.
 * - If this cadenza is embedded, then use parentWindowPostMessageTarget (or subscribeToEvent, postEvent global
 *   functions). This will send messages to parent window.
 * - If this cadenza has custom applications opened in popup or another window, then create instance of
 *   PostMessageTarget for that window.
 *
 */
export class PostMessageTarget {
  readonly #subscriptions: [IncomingCadenzaEventType, Subscriber][] = [];

  readonly #target;
  readonly #targetOrigin;

  /**
   *
   * @param targetOrigin - ill be used in each postEvent call for this instance. Used for security.
   * @param target - messages in postEvent will be sent to this window. If no window is provided, then
   *   this PostMessageTarget will listen for events from both parent and any other customer application window.
   */
  constructor(targetOrigin: string | (() => Promise<string>), target?: Window) {
    this.#target = target;
    this.#targetOrigin = lazy(targetOrigin);
  }

  /**
   * Subscribe to events from the target window.
   *
   * @param type - The event type
   * @param subscriber - The subscriber function
   * @return An unsubscribe function
   */
  subscribeToEvent<T extends IncomingCadenzaEventType>(type: T, subscriber: Subscriber<T>): () => void {
    const subscriptions = this.#subscriptions;
    if (subscriptions.length === 0) {
      window.addEventListener('message', (e) => this.__onMessage__(e));
    }
    subscriptions.push([type, subscriber]);
    return () => {
      subscriptions.forEach(([subscriptionType, subscriptionSubscriber], i) => {
        if (subscriptionType === type && subscriptionSubscriber === subscriber) {
          subscriptions.splice(i, 1);
        }
      });
      if (subscriptions.length === 0) {
        window.removeEventListener('message', (e) => this.__onMessage__(e));
      }
    };
  }

  /**
   * Post an event to the target window.
   * The event is sent using `postMessage()` to the target window.
   *
   * @param type - The event type
   * @param [detail] - The event detail
   */
  postEvent<T extends OutgoingCadenzaEventType>(type: T, detail?: OutgoingCadenzaEvent<T>['detail']) {
    const target = this.#target;
    assertNonNullable(target, 'This PostMessageTarget has no target window defined.');
    this.#targetOrigin().then((targetOrigin) => {
      const event = { type, detail };
      logger.log(`postEvent() to ${targetOrigin}`, event);
      target.postMessage(event, targetOrigin);
    });
  }

  async __onMessage__(event: MessageEvent<IncomingCadenzaEvent>) {
    const target = this.#target;
    if (target && target !== window.parent && event.source !== target) {
      return;
    }
    const targetOrigin = await this.#targetOrigin();
    if (targetOrigin !== '*' && event.origin !== targetOrigin) {
      return;
    }

    logger.log('Received message', event);

    const cadenzaEvent = event.data;
    cadenzaEvent.responsePort = event.ports[0];
    this.#subscriptions.forEach(([type, subscriber]) => {
      if (type === cadenzaEvent.type) {
        subscriber(cadenzaEvent as never);
      }
    });
  }
}

const parentWindowPostMessageTarget = new PostMessageTarget(getParentTargetOrigin, window.parent);

/**
 * Post an event to the customer application in parent window. (This cadenza is embedded)
 *
 * The event is sent using `postMessage()` to the `parent` window. By default, the `location.origin` is used as the target origin.
 * If a `webApplication` URL parameter with the ID of an external link is present, the origin is taken form the external link.
 *
 * @param type - The event type
 * @param [detail] - The event detail
 */
export function postEvent<T extends OutgoingCadenzaEventType>(type: T, detail?: OutgoingCadenzaEvent<T>['detail']) {
  parentWindowPostMessageTarget.postEvent(type, detail);
}

/**
 * Subscribe to events from the customer application in parent window. (This cadenza is embedded)
 *
 * @param type - The event type
 * @param subscriber - The subscriber function
 * @return An unsubscribe function
 */
export function subscribeToEvent<T extends IncomingCadenzaEventType>(type: T, subscriber: Subscriber<T>): Teardown {
  return parentWindowPostMessageTarget.subscribeToEvent(type, subscriber);
}

/** @knipignore */
// Exported for testing
export const __onMessage__ = parentWindowPostMessageTarget.__onMessage__.bind(parentWindowPostMessageTarget);

async function getParentTargetOrigin() {
  const webApplication = window.Disy.webApplication;
  if (webApplication) {
    if (webApplication === '*') {
      return '*';
    }
    try {
      const targetOrigin = await getTargetOrigin(webApplication);
      if (targetOrigin) {
        return targetOrigin;
      }
    } catch (error) {
      logger.error(
        `Could not resolve origin for given external link: ${webApplication}. ` +
          'Maybe the link does not exist or the user has no view privilege for it?',
        error,
      );
    }
  }
  return location.origin;
}

type RequestResponse<T extends IncomingCadenzaEventType> = T extends 'getData' ? Blob : never;

export async function handleRequest<T extends IncomingCadenzaEventType>(
  event: IncomingCadenzaEvent<T>,
  promise: Promise<void | RequestResponse<T>>,
) {
  const port = event.responsePort;
  assertNonNullable(port, 'The response port is required to handle a request');
  try {
    port.postMessage({
      type: `${event.type}:success`,
      detail: await promise,
    });
  } catch {
    port.postMessage({ type: `${event.type}:error` });
  }
}
