import groupBy from 'lodash-es/groupBy';

if (!Object.groupBy) {
  Object.groupBy = groupBy;
}

if (!Map.groupBy) {
  Map.groupBy = <K, T>(items: Iterable<T>, keySelector: (item: T, index: number) => K) =>
    new Map(Object.entries(groupBy(items, keySelector))) as Map<K, T[]>;
}
